import React, {useContext, useState} from 'react'
import ColorContext from '../context/ColorContext'
import {uniqueId} from 'lodash'
import {Box} from '@chakra-ui/react'

const TalentTriangle = ({talent}) => {
  const { color } = useContext(ColorContext)
  const [ gida ] = useState(uniqueId('gradPrefix-'))
  const [ gidb ] = useState(uniqueId('gradPrefix-'))
  const [ gidc ] = useState(uniqueId('gradPrefix-'))
  const [ gidd ] = useState(uniqueId('gradPrefix-'))
  const [ gide ] = useState(uniqueId('gradPrefix-'))

  return (
    <Box
      sx={{
        position: 'relative',
        paddingBottom: '116.12%',
        cursor: 'pointer',
        'img, svg': {
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          height: 'auto'
        },
        '.underTriangle': {
          transition: 'all 0.3s ease-in-out',
        },
        '.underTriangle2': {
          transition: 'all 0.2s ease-in-out',
        },
        '&:hover': {
          '.underTriangle': {
            transform: 'translate(2px,6px)'
          },
          '.underTriangle2': {
            transform: 'translate(9px,21px)'
          }
        }
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 396.3 459.7"
        enableBackground="new 0 0 396.3 459.7" 
      >
        <filter id={gida}>
          <feGaussianBlur result="coloredBlur" stdDeviation="1.5" />
          <feMerge>
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id={gidc}>
          <feGaussianBlur result="coloredBlur" stdDeviation="12" />
          <feMerge>
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <g filter={`url(#${gida})`}>
          <linearGradient id={gidb} gradientUnits="userSpaceOnUse" x1="51.3224" y1="329.2282" x2="361.4167" y2="329.2282">
            <stop offset="0" style={{stopColor: color.alpha}} />
            <stop offset="1" style={{stopColor: color.bravo}} />
          </linearGradient>
          {/* <path className="underTriangle2" fill="none" stroke={`url(#${gidb})`} strokeWidth="3" strokeMiterlimit="10" d="M360.4 229.4 50.3 319.6l261.9 105.5z" /> */}
          {/* <path className="underTriangle" fill="none" stroke={`url(#${gidb})`} strokeWidth="4" strokeMiterlimit="10" d="M361.4 231.4 51.3 321.6l261.9 105.5z" /> */}
          <path className="underTriangle" fill="none" stroke={`url(#${gidb})`} strokeWidth="4" strokeMiterlimit="10" d="M360.4 229.4 50.3 319.6l261.9 105.5z" />
        </g>
        <g filter={`url(#${gidc})`}>
          <linearGradient id={gidd} gradientUnits="userSpaceOnUse" x1="43.3224" y1="313.2282" x2="353.4167" y2="313.2282">
            <stop offset="0" style={{stopColor: color.alpha}} />
            <stop offset="1" style={{stopColor: color.bravo}} />
          </linearGradient>
          <path fill={`url(#${gidd})`} fillOpacity="1" d="M353.4 215.4 43.3 305.6l261.9 105.5z" />
          <linearGradient id={gide} gradientUnits="userSpaceOnUse" x1="43.3224" y1="313.2282" x2="353.4167" y2="313.2282">
            <stop offset="0" style={{stopColor: color.alpha}} />
            <stop offset="1" style={{stopColor: color.bravo}} />
          </linearGradient>
          <path fill="none" stroke={`url(#${gide})`} strokeWidth="6" strokeMiterlimit="10" d="M353.4 215.4 43.3 305.6l261.9 105.5z" />
        </g>
      </svg>
      <img src={`/images/talent-${talent}-masked.webp`} alt={talent} style={{opacity:'90%'}} />
    </Box>
  )
}

export default TalentTriangle
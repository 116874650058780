import React, { useContext } from 'react'
import ColorContext from '../context/ColorContext'
import { Box } from '@chakra-ui/react'
import { H3 } from '../theme/Headings'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Testimonials = ({testis}) => {
  const { color } = useContext(ColorContext)

  const settings = {
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 4000,
    dots: true,
    arrows: false,
    infinite: true,
    adaptiveHeight: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    fade: true,
  };

  return (
    <Slider {...settings}>
      {testis.map((testi, i) => {
        return (
          <Box
            key={`testi-slider-key-${i}`}
            textAlign="center"
            width="100%"
            sx={{
              h3: {
                background: `-webkit-linear-gradient(right, ${color.alpha}, ${color.bravo})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }
            }}
          >
            <H3>{testi.testiQuote}</H3>
            <small>—{testi.testiAttribution}</small>
          </Box>
        )
      })}
    </Slider>
  )
}

export default Testimonials